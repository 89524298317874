<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import type { User } from "@/types/settings";
import BaseIcon from "./BaseIcon.vue";
import UserAuthButtons from "@/components/ui-elements/UserAuthButtons.vue";

interface Props {
  main: any;
  user?: User;
  pathname: string;
}
defineProps<Props>();
</script>

<template>
  <div class="lg:hidden right-0 absolute w-full">
    <Popover v-slot="{ open }" class="relative flex justify-end">
      <PopoverButton
        :class="open ? '' : 'text-opacity-90'"
        class="mr-5 group inline-flex items-center rounded-md bg-transparent px-2 py-1 text-base font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
      >
        <span class="sr-only">Open menu</span>
        <BaseIcon icon="Fa6SolidBars" class="h-6 w-6" aria-hidden="true"/>
      </PopoverButton>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel class="absolute left-1/2 z-10 mt-14 -translate-x-1/2 transform min-w-full">
          <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="relative grid gap-4 bg-white p-7">
              <div v-for="item in main" :key="item.name">
                <a
                  :href="item.url"
                  class="nav-link w-fit flex items-center rounded-lg p-2 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-50"
                  :class="pathname === `${item.url}/` || pathname === item.url ? 'active' : ''"
                >
                  <p class="text-sm font-medium">
                    {{ item.name }}
                  </p>
                </a>
                <div class="flex flex-col gap-2 ml-1 text-dark">
                  <template v-if="item.hasChildren" v-for="(child, index) in item.children" :key="index">
                    <a
                      :href="child.url"
                      class="ml-2 flex gap-2 items-center"
                      :class="pathname === `${item.url}/` || pathname === item.url ? 'active' : ''"
                    >
                      <div class="bg-primary h-2 w-2 rounded-full"></div>
                      <p class="text-sm font-medium">{{ child.name }}</p>
                    </a>
                  </template>
                </div>
              </div>
            </div>
            <!-- <div class="bg-gray-50 p-5">
              <UserAuthButtons :user="user" />
            </div> -->
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>
